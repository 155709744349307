@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .active {
    @apply hover:text-primary transition duration-300 text-primary;
  }

  .inActive {
    @apply hover:text-primary transition duration-300 text-main;
  }

  .select {
    @apply block rounded-md py-1 px-2 border-main bg-transparent text-white focus:outline-none  leading-tight;
    option {
      @apply bg-main;
    }

    @media screen and (max-width: 600px) {
      @apply text-main;
      option {
        @apply bg-main text-white;
      }
    }
  }


}
@layer base {
  h1 {
    @apply text-3xl font-bold; /* Tailwind sınıfları */
  }
  h2 {
    @apply text-2xl font-semibold;
  }
  h3 {
    @apply text-xl font-medium;
  }
  h4 {
    @apply text-lg font-medium;
  }
  h5 {
    @apply text-base font-medium;
  }
  h6 {
    @apply text-sm font-medium;
  }
}



html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.line {

  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: white;
    margin-right: 10px;
    margin-left: 10px;
    right: 0;
    top: 0;

  }


}

input[type=checkbox] {
  transform: scale(1.2);
}

.footer {
  background: linear-gradient(rgb(0 0 0 / 85%) 0 0),
  url("../images/footer-image.jpg") no-repeat center/cover;
}

.home-image {
  background: linear-gradient(rgb(0 0 0 / 35%) 0 0),
  url("../images/homepage-image.jpg") no-repeat center/cover;

}

.about-image {
  background: linear-gradient(rgb(0 0 0 / 35%) 0 0),
  url("../images/about-page.jpg") no-repeat center/cover;
  height: 250px;

}

.doctors-image {
  background: linear-gradient(rgb(0 0 0 / 35%) 0 0),
  url("../images/doctors-header.jpg") no-repeat center/cover;
  height: 250px;

}

.login-image {
  background: url("../images/login.png") no-repeat center/cover;
  width: 580px;
  height: 560px;
}

.accountHeight {
  max-height: 680px;
}

.login-line {
  position: relative;


  &::before {
    content: attr(text);
    display: block;
    color: white;
    border-radius: 5px;
    position: absolute;
    padding: 0 3px;
    background-color: #212529;
    left: 0;
    right: 0;
    margin: auto;
    top: -10px;
    z-index: 1;
    width: 55px;
  }
}

.about-line {
  position: relative;


  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 150px;
    height: 1px;
    background-color: #4EAAC8;
    left: 0;
    bottom: -16px;
  }
}

.about-line-long {

  display: block;
  position: absolute;
  width: 330px;
  height: 1px;
  background-color: #4EAAC8;
  left: 23px;
  bottom: -10px;


}

.react-datepicker {
  transform: scale(1.1);
}

.journal-scroll::-webkit-scrollbar {
  width: 2px;
  cursor: pointer;
  background-color: black;

}

.journal-scroll::-webkit-scrollbar-track {
  background-color: red;
  cursor: pointer;

}

.journal-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: red;
  /*outline: 1px solid slategrey;*/
}

@media screen and (max-width: 600px) {
  .home-image {
    background: linear-gradient(rgb(0 0 0 / 35%) 0 0),
    url("../images/homepage-image.jpg") no-repeat right/cover;
    height: 350px;

  }

  .about-line-long {

    display: block;
    position: absolute;
    width: 270px;
    height: 1px;
    background-color: #4EAAC8;
    left: 34px;
    bottom: -10px;


  }
  .accountHeight {
    max-height: 130px;
  }

  .fc-toolbar {
    font-size: 12px;
  }
}

.form-group {
  @apply flex items-start flex-col mb-4;


}

.payment-label {
  @apply text-gray-600;
}


.payment-input {
  @apply bg-white w-full px-4 py-2 duration-100 border-2 border-gray-200 rounded-lg focus:outline-none focus:border-gray-500;
}


.Toastify__toast-container {
  z-index: 999999 !important;
}


.user-verify-tc-info {
  &:hover {
    // next div
    & + div {
      display: block;
    }
  }

}

select{
  &:not(.appearance-none){


    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='32' height='16' viewBox='0 0 32 16' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M0 0L16 16L32 0H0Z' fill='black'/> </svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 15px;
  }

}
